import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  EMMA: string;
  constructor() {
    this.EMMA =
      'https://bancolombia.sharepoint.com/sites/pa-vsc/SitePages/AdministracionDeEntornosDePrueba.aspx';
  }

  ngOnInit(): void {}
}
