<div class="bc-height container bc-center">
  <div class="card bc-outbox">
    <div class="card-body">
      <h2 class="card-title w-100">
        Bienvenido al Centro de Servicios de EMMA
      </h2>
      <div class="clearfix"></div>
      <div class="row">
        <div class="col-md-12">
          <div class="row row-cols-md-3">
            <div class="col mb-4" *ngFor="let option of menu">
              <div class="card bc-color" (click)="goTo(option.path)">
                <div class="bc-center pt-2">
                  <img src="{{ option.icon }}" class="img-fluid" alt="" />
                </div>
                <div class="card-body bc-center">
                  <h4 class="card-title">{{ option.description }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
