<nav class="navbar bc-title-navbar">
  <h1 class="d-none d-sm-block left">Administrador de Ambientes</h1>
  <div class="ml-auto bc-name">
    <h5>{{ userName }}</h5>
  </div>
  <div class="lh-0">
    <i
      class="fas fa-sign-out-alt fa-fw bc-pointer"
      (click)="logout()"
      id="logout"
      aria-hidden="true"
    ></i>
  </div>
</nav>
