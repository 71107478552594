import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InitiativeTypeService {
  readonly API = environment.config.endpoints.API;

  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  getInitiativeTypes(): Observable<any> {
    const ENDPOINT = `${this.API}/initiativeType/findAll`;
    return this.http.get<any>(ENDPOINT, this.httpOptions);
  }
}
