import { MainMenu } from 'src/app/models/center-of-services/main-menu';
import { pluck, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MainMenuService {
  readonly API = environment.config.endpoints.CSE;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  getMainMenu(): Observable<any> {
    // const ENDPOINT = `https://run.mocky.io/v3/afb1e272-7611-4d1f-a29d-bf40364ad92a`;
    const ENDPOINT = `${this.API}/menu/findMenu`;
    return this.http.get<Array<MainMenu>>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response)
    );
  }

  getAllCategory(): Observable<any> {
    // const ENDPOINT = `https://run.mocky.io/v3/ba298dc9-4bda-409c-8147-80feb9d96aba`;
    const ENDPOINT = `${this.API}/categories/findAll`;
    return this.http.get<Array<any>>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response)
    );
  }

  getSubCategory(category: string): Observable<any> {
    // const ENDPOINT = `https://run.mocky.io/v3/c8dba9e6-4c3e-4861-9219-452cfbf088be`;
    const ENDPOINT = `${this.API}/subcategories/findbycategories?categories=${category}`;
    return this.http.get<Array<any>>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response)
    );
  }
}
