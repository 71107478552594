import { pluck, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';
import { Applicative } from 'src/app/models/applicative';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  readonly API = environment.config.endpoints.API;

  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  getApplications(): Observable<any> {
    const ENDPOINT = `${this.API}/applicative/findAll`;
    return this.http.get<any>(ENDPOINT, this.httpOptions);
  }

  findAllApplicatives(): Observable<any> {
    const ENDPOINT = `${this.API}/applicative/findAll`;
    return this.http.get<Array<Applicative>>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response)
    );
  }

  findLikeName(name: string): Observable<any> {
    const ENDPOINT = `${this.API}/applicative/listApplicativeFilter/${name}`;
    return this.http.get<Array<Applicative>>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response)
    );
  }

  findApplicativeById(applicativeId: number): Observable<any> {
    const ENDPOINT = `${this.API}/applicative/findById/${applicativeId}`;
    return this.http.get<Applicative>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response)
    );
  }

  createApplicative(applicative: Applicative): Observable<any> {
    const ENDPOINT = `${this.API}/applicative/create`;
    return this.http.post<any>(ENDPOINT, applicative).pipe(
      pluck('data'),
      map((response) => response)
    );
  }

  updateApplicative(
    applicative: Applicative,
    applicativeId: number
  ): Observable<any> {
    const ENDPOINT = `${this.API}/applicative/update/${applicativeId}`;
    return this.http.post<any>(ENDPOINT, applicative).pipe(
      pluck('data'),
      map((response) => response)
    );
  }
}
