import { MsalService } from '@azure/msal-angular';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SessionService } from './session.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastr: ToastrService,
    private sessionService: SessionService,
    private router: Router,
    private msalService: MsalService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const CODE_401 = 401;
    const CODE_403 = 403;
    const CODE_409 = 409;
    const CODE_500 = 500;

    return next.handle(req).pipe(
      tap(() => {}),
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === CODE_403 || error.status === CODE_401) {
            this.router.navigate(['emma']);
          } else if (error.status === CODE_409) {
            this.transferSession();
          } else if (
            error.status === CODE_500 &&
            error.error.message === 'Expired JWT'
          ) {
            this.logout();
          }
        }
        return throwError(error);
      })
    );
  }

  transferSession() {
    this.sessionService.transferSession().subscribe((response: any) => {});
  }

  logout() {
    this.msalService.logoutRedirect();
  }
}
