import { MenuRoutes } from 'src/app/models/menu-routes';
import { MenuService } from './../../../services/menu/menu.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-navbar',
  templateUrl: './main-navbar.component.html',
  styleUrls: ['./main-navbar.component.css'],
})
export class MainNavbarComponent implements OnInit {
  active;
  EMMA: string;

  menuRoutes: Array<MenuRoutes>;

  constructor(private menuService: MenuService, private router: Router) {
    this.EMMA =
      'https://grupobancolombia.visualstudio.com/Vicepresidencia%20Servicios%20de%20Tecnolog%C3%ADa/_wiki/wikis/Vicepresidencia%20Servicios%20de%20Tecnolog%C3%ADa.wiki/25812/Agenda-2.0';
  }

  ngOnInit(): void {
    this.findMenu();
  }

  findMenu() {
    this.menuService.findMenu().subscribe((response: Array<MenuRoutes>) => {
      this.menuRoutes = response;
    });
  }

  goTo(path: string) {
    this.router.navigate([path]);
  }
}
