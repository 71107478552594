<div class="container-fluid bc-footer">
  <footer>
    <div class="col-md-2">
      <img
        src="/assets/img/logos/BC_logo_primario_negativo.png"
        class="img-fluid"
        alt="Bank Logo"
      />
    </div>
  </footer>
</div>
