import { Component, OnInit } from '@angular/core';
import { MainMenuService } from 'src/app/services/center-of-services/menu.service';
import { MainMenu } from 'src/app/models/center-of-services/main-menu';
import { Router } from '@angular/router';

@Component({
  selector: 'app-center-of-services',
  templateUrl: './center-of-services.component.html',
  styleUrls: ['./center-of-services.component.css'],
})
export class CenterOfServicesComponent implements OnInit {
  menu: Array<MainMenu> = [];
  constructor(
    private mainMenuService: MainMenuService,
    private router: Router
  ) {}

  ngOnInit(): void {
    //this.getMainMenu();
  }

  getMainMenu() {
    this.mainMenuService
      .getMainMenu()
      .subscribe((response: Array<MainMenu>) => {
        this.menu = response;
      });
  }

  goTo(url: string) {
    this.router.navigate([url]);
  }
}
