export const environment = {
  production: false,
  name: 'admin-ambientes',
  config: {
    tenant: `b5e244bd-c492-495b-8b10-61bfd453e423`,
    clientId: `2848b8f8-0b93-48e3-aa17-a69bd4a7ca5c`,
    authority: `https://login.microsoftonline.com/b5e244bd-c492-495b-8b10-61bfd453e423`,
    redirectUri: `https://emma-qa.appst.ambientesbc.com/main/center-of-services`,
    postLogoutRedirectUri: `${window.location.origin}/emma`,
    endpoints: {
      NMS: 'https://agendalb-qa.appst.ambientesbc.com',
      API: 'https://agendalb-qa.appst.ambientesbc.com/apibooking',
      CSE: 'https://agendalb-qa.appst.ambientesbc.com/apiselfmanagement',
    },
  },
  environment: 'certification',
};
