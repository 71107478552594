import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { SessionService } from 'src/app/services/interceptors/session.service';

@Component({
  selector: 'app-main-title-navbar',
  templateUrl: './main-title-navbar.component.html',
  styleUrls: ['./main-title-navbar.component.css'],
})
export class MainTitleNavbarComponent implements OnInit, OnDestroy {
  userName: string;
  userEmail: string;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private authService: MsalService,
    private broadcastService: MsalBroadcastService,
    private sessionService: SessionService
  ) {}
  

  async ngOnInit() {
    await this.authService.instance.initialize();
    await this.authService.instance.handleRedirectPromise();
    
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) =>
          msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
    .subscribe((result: EventMessage )=>{
      const authResult = result.payload as AuthenticationResult;
      this.authService.instance.setActiveAccount(authResult.account);
      this.setUserDetails(authResult.account?.idTokenClaims);
    })

    this.setInitialUserDetails();
  }

  setUserDetails(idTokenClaims: any) {
    if(idTokenClaims) {
      this.userName = idTokenClaims.name || null;
      this.userEmail = idTokenClaims.preferred_username || null;
    }
  }

  setInitialUserDetails() {
    const activeAccount = this.authService.instance.getActiveAccount();
    if(activeAccount) {
      this.setUserDetails(activeAccount.idTokenClaims);
    }
  }

  logoutCSE() {
    this.sessionService.closeSessionCSE().subscribe((response: any) => {
      this.logout();
    });
  }

  logout() {
    this.sessionService.closeSession().subscribe((response: any) => {});
    this.authService.logoutRedirect();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
