<div class="container-fluid bc-height bc-center">
  <div class="card bc-develop shadow-lg">
    <img
      src="/assets/img/icons/configuracion.svg"
      class="card-img-top bc-develop-img"
      alt="module develop"
    />
    <div class="card-body">
      <h2 class="bc-h2">¡Funcionalidad en desarrollo!</h2>
      <h4 class="bc-h4">
        Muy pronto podrás llevar a cabo el proceso de reserva de dispositivos
        móviles para apoyar procesos de pruebas manuales o automatizadas.
      </h4>
    </div>
  </div>
</div>
