import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-develop-cell-farm',
  templateUrl: './develop-cell-farm.component.html',
  styleUrls: ['./develop-cell-farm.component.css'],
})
export class DevelopCellFarmComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
