import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-develop-observability',
  templateUrl: './develop-observability.component.html',
  styleUrls: ['./develop-observability.component.css'],
})
export class DevelopObservabilityComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
