<ul
  ngbNav
  #nav="ngbNav"
  [(activeId)]="active"
  class="nav-tabs sticky-top bc-navbar"
>
  <li [ngbNavItem]="1">
    <a ngbNavLink><h5>Inicio</h5></a>
    <ng-template ngbNavContent>
      <app-home></app-home>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink><h5>¿Qué es?</h5></a>
    <ng-template ngbNavContent>
      <app-about-us></app-about-us>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav" class="mt-5"></div>
