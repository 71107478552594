import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-develop-windows',
  templateUrl: './develop-windows.component.html',
  styleUrls: ['./develop-windows.component.css'],
})
export class DevelopWindowsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
