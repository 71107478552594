<div class="container-fluid bc-height">
  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-4">
          <img
            src="/assets/img/icons/agenda.svg"
            class="img-fluid calendar"
            alt="calendar"
          />
        </div>
        <div class="col-md-8">
          <h5>
            La Agenda de Ambientes proporciona una visión unificada sobre el uso
            de los Ambientes No Productivos por parte de los diferentes equipos
            de Desarrollo, Certificación y Disponibilidad de la Dirección de Servicios de Tecnología de la Información, por medio
            de los diferentes usos en pruebas de aplicaciones, dispositivos,
            cierres de depósitos, cierres contables, cajeros físicos entre otros
            elementos.
          </h5>
        </div>
        <div class="col-md-12">
          <h5>
            Le permite a los equipos de trabajo
            <strong>"hacerse visibles"</strong> e identificar con cual otro
            equipo comparten el ambiente en el que están trabajando. Sus
            reservas activas en la Agenda Ambientes le permitirán estar enterado
            de los incidentes, cambios o mantenimientos asociados a los
            componentes que hacen parte de su ambiente de pruebas, mediante los
            comunicados emitidos por el equipo de Administración de Entornos de pruebas a través de los diferentes
            medios.
          </h5>
        </div>
      </div>
    </div>
    <div class="col-md-6 d-none d-sm-block">
      <img
        src="/assets/img/landing-page/touch_table.png"
        class="img-fluid"
        alt="touch table"
      />
    </div>
  </div>
</div>
