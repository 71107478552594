import { Analyst } from 'src/app/models/analyst';
import { map, pluck } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AnalystService {
  readonly API = environment.config.endpoints.API;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  findLikeName(name: string): Observable<any> {
    const ENDPOINT = `${this.API}/analyst/findLikeName/${name || ''}`;
    return this.http.get<Array<Analyst>>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response)
    );
  }
}
