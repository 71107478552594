<ul
  ngbNav
  #nav="ngbNav"
  [(activeId)]="active"
  class="nav-tabs sticky-top bc-navbar"
>
  <li *ngFor="let menu of menuRoutes; let index = index" [ngbNavItem]="index">
    <div *ngIf="menu.children.length > 0">
      <li ngbDropdown class="nav-item">
        <a href (click)="(false)" class="nav-link" ngbDropdownToggle>{{
          menu.description
        }}</a>
        <div ngbDropdownMenu>
          <div *ngFor="let child of menu.children">
            <button
              (click)="goTo(child.path)"
              class="btn btn-link"
              ngbDropdownItem
            >
              {{ child.description }}
            </button>
            <div class="dropdown-divider"></div>
          </div>
        </div>
      </li>
    </div>
    <div
      *ngIf="
        menu.roleName !== 'ROLE_ANALISTA_EMMA' && menu.roleName !== 'ROLE_ADMIN'
      "
    >
      <a routerLink="{{ menu.path }}" ngbNavLink>{{ menu.description }}</a>
    </div>
  </li>
</ul>
<div [ngbNavOutlet]="nav" class="mt-5"></div>
