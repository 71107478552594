import { BehaviorSubject, Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private loginStatus$ = new BehaviorSubject<boolean>(false);
  readonly API = environment.config.endpoints.API;
  readonly NMS = environment.config.endpoints.NMS;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  notifyLoginStatuschange(status: boolean) {
    this.loginStatus$.next(status);
  }

  getLoginStatus() {
    return this.loginStatus$.asObservable();
  }

  saveSession(): Observable<any> {
    const ENDPOINT = `${this.API}/session/saveSession`;
    return this.http.post<any>(ENDPOINT, this.httpOptions);
  }

  closeSessionCSE(): Observable<any> {
    const ENDPOINT = `${this.NMS}/logout`;
    return this.http.delete<any>(ENDPOINT, this.httpOptions);
  }

  closeSession(): Observable<any> {
    const ENDPOINT = `${this.API}/session/closeSession`;
    return this.http.post<any>(ENDPOINT, this.httpOptions);
  }

  transferSession(): Observable<any> {
    const ENDPOINT = `${this.API}/session/transferSession`;
    return this.http.post<any>(ENDPOINT, this.httpOptions);
  }
}
