import { Server } from './../models/server';
import { ComponentApp } from './../models/component-app';
import { pluck, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ComponentAppService {
  readonly API = environment.config.endpoints.API;

  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  getComponentsByApplicationId(applicationId: string): Observable<any> {
    const ENDPOINT = `${this.API}/components/findByApplicative/${applicationId}`;
    return this.http.get<any>(ENDPOINT, this.httpOptions);
  }

  findAllComponents(): Observable<any> {
    const ENDPOINT = `${this.API}/components/findAll`;
    return this.http.get<Array<ComponentApp>>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response)
    );
  }

  findLikeDescription(name: string): Observable<any> {
    const ENDPOINT = `${this.API}/components/listComponentsFilter/${name}`;
    return this.http.get<Array<ComponentApp>>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response)
    );
  }

  findComponentById(componentId: number): Observable<any> {
    const ENDPOINT = `${this.API}/components/findById/${componentId}`;
    return this.http.get<ComponentApp>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response)
    );
  }

  createComponent(componentApp: ComponentApp): Observable<any> {
    const ENDPOINT = `${this.API}/components/create`;
    return this.http.post<any>(ENDPOINT, componentApp).pipe(
      pluck('data'),
      map((response) => response)
    );
  }

  updateComponent(
    component: ComponentApp,
    componentId: number
  ): Observable<any> {
    const ENDPOINT = `${this.API}/components/update/${componentId}`;
    return this.http.post<any>(ENDPOINT, component).pipe(
      pluck('data'),
      map((response) => response)
    );
  }

  findAllServers(): Observable<any> {
    const ENDPOINT = `${this.API}/server/findAll`;
    return this.http.get<Server>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response)
    );
  }
}
