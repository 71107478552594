<div class="container-fluid bc-height bc-center">
  <div class="row">
    <div class="col-md-6">
      <div class="row bc-center">
        <div class="col-md-12 bc-center">
          <img
            src="/assets/img/icons/ayuda.svg"
            class="img-fluid help"
            alt="help"
          />
        </div>
        <div class="col-md-10">
          <h5>
            Si tienes dudas acerca del uso del aplicativo te invitamos a visitar
            el espacio en la wiki:
          </h5>
        </div>
        <div class="col-md-10 bc-center">
          <a class="btn btn-primary" href="{{ INFO }}" target="_blank" rel="noopener noreferrer" role="button"
            >Mas información</a
          >
        </div>
        <div class="col-md-10">
          <div class="clearfix bc-clear"></div>
          <h5>
            Si llegas a encontrar un fallo en el aplicativo o requieres mas
            informacion te invitamos a unirte al grupo de Teams
          </h5>
        </div>
        <div class="col-md-10 bc-center">
          <a class="btn btn-primary" href="{{ TEAMS }}" target="_blank" rel="noopener noreferrer" role="button"
            >Unete en Teams</a
          >
        </div>
      </div>
    </div>
    <div class="col-md-6 d-none d-sm-block">
      <img
        src="/assets/img/landing-page/touch_table.png"
        class="img-fluid"
        alt="touch table"
      />
    </div>
  </div>
</div>
