import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CenterOfServicesComponent } from './components/center-of-services/center-of-services.component';
import { HomeComponent } from './components/landing-page/home/home.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { DevelopCellFarmComponent } from './components/main/develop-cell-farm/develop-cell-farm.component';
import { DevelopObservabilityComponent } from './components/main/develop-observability/develop-observability.component';
import { DevelopWindowsComponent } from './components/main/develop-windows/develop-windows.component';
import { HelpComponent } from './components/main/help/help.component';
import { MainComponent } from './components/main/main.component';
import { AuthGuard } from './services/guards/auth-guard.guard';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/emma',
    pathMatch: 'full',
  },
  {
    path: 'emma',
    component: LandingPageComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent,
      },
    ],
  },
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: CenterOfServicesComponent },
      {
        path: 'booking',
        loadChildren: () =>
          import('./components/booking/booking.module').then(
            (m) => m.BookingModule
          ),
      },
      {
        path: 'windows',
        loadChildren: () =>
          import('./components/windows/windows.module').then(
            (m) => m.WindowsModule
          ),
      },
      {
        path: 'center-of-services',
        loadChildren: () =>
          import(
            './components/center-of-services/center-of-services.module'
          ).then((m) => m.CenterOfServicesModule),
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./components/admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'observability',
        component: DevelopObservabilityComponent,
      },
      {
        path: 'cell-farm',
        component: DevelopCellFarmComponent,
      },
      {
        path: 'help',
        component: HelpComponent,
      },
      {
        path: 'windows-development',
        component: DevelopWindowsComponent,
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    enableTracing: false
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
