<div class="container-fluid bc-height">
  <div><h1>¡Bienvenido!</h1></div>
  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12">
          <h5>
            Esta herramienta te permite gestionar y planificar el uso coordinado
            de los Ambientes No Productivos, mediante el agendamiento de
            componentes compartidos, ventanas de pruebas o mantenimientos y
            notificaciones de nuevos versionamientos. Todo con el objetivo de hacer tu paso por los
            ambientes más productivo.
          </h5>
        </div>
        <div class="col-md-12 clearfix-btn"></div>
        <div class="col-md-12">
          <div class="btn-group" role="group" aria-label="Buttons for redirect">
            <!-- <a class="btn bc-aa" role="button" aria-disabled="true">
              Agendar Ambiente</a
            >
            <a class="btn bc-vn" role="button" aria-disabled="true">
              Ventanas</a
            >
            <a class="btn bc-ov" role="button" aria-disabled="true">
              Observabilidad</a
            >
            <a class="btn bc-gc" role="button" aria-disabled="true">
              Granja de Celulares</a
            > -->
          </div>
        </div>
        <div class="col-md-12 clearfix"></div>
        <div class="col-md-6">
          <a class="btn bc-emma" href="{{ EMMA }}" target="_blank" rel="noopener noreferrer" role="button"
            >Ir a Sitio SharePoint EMMA</a
          >
        </div>
      </div>
    </div>
    <div class="col-md-6 d-none d-sm-block">
      <img
        src="/assets/img/landing-page/touch_table.png"
        class="img-fluid"
        alt="touch table"
      />
    </div>
  </div>
</div>
